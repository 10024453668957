var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container-scroller"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 grid-margin stretch-card"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h3',{staticClass:"card-title text-white rounded p-3 mb-5 text-center",staticStyle:{"background-color":"#0b5d3f","box-shadow":"0 4px 6px rgba(0, 0, 0, 0.1)"}},[_vm._v(" Ajouter une Affectation de terminal (caisse) ")]),_c('form',{staticClass:"forms-sample row",on:{"submit":_vm.addterminal}},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_vm._m(1),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.user_id),expression:"user_id"}],staticClass:"form-control",class:{
                    'form-control': true,
                    'is-invalid': _vm.formErrors.user_id,
                  },attrs:{"id":"user_id"},on:{"input":function($event){return _vm.clearError('user_id')},"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.user_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":""}},[_vm._v("Sélectionnez un utilisateur")]),_vm._l((_vm.users),function(user){return _c('option',{key:user.id,domProps:{"value":user.id}},[_vm._v(" "+_vm._s(user.name)+" "+_vm._s(user.prenom)+" ")])})],2),(_vm.formErrors.user_id)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.formErrors.user_id)+" ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_vm._m(2),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.terminal_id),expression:"terminal_id"}],staticClass:"form-control",class:{
                    'form-control': true,
                    'is-invalid': _vm.formErrors.terminal_id,
                  },attrs:{"id":"terminal_id"},on:{"input":function($event){return _vm.clearError('terminal_id')},"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.terminal_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":""}},[_vm._v("Sélectionnez un terminal")]),_vm._l((_vm.affectterminals),function(affectterminal){return _c('option',{key:affectterminal.id,domProps:{"value":affectterminal.id}},[_vm._v(" "+_vm._s(affectterminal.nom)+" ")])})],2),(_vm.formErrors.terminal_id)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.formErrors.terminal_id)+" ")]):_vm._e()])]),_c('div',{staticClass:"d-flex justify-content-between col-12 flex-row"},[_c('button',{staticClass:"btn btn-secondary btn-medpay-gray",on:{"click":_vm.cancel}},[_vm._v(" Annuler ")]),_c('button',{staticClass:"btn btn-success btn-medpay-green",attrs:{"type":"submit"}},[_vm._v(" Ajouter ")])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-header"},[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('a',{staticClass:"text-dark font-weight-bold",attrs:{"href":"javascript:void(0);"}},[_vm._v("Paramétrage")])]),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v(" AffectTerminal ")]),_c('li',{staticClass:"breadcrumb-item active text-success font-weight-bold",attrs:{"aria-current":"page"}},[_vm._v(" Ajouter ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"user_id"}},[_vm._v("Utilisateur "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"terminal_id"}},[_vm._v("Terminal "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])])
}]

export { render, staticRenderFns }